import React, { Component } from 'react';
import './content.css'

class ReasearchBlock extends Component {
    render() {
        return (
            <div className='reasearch-block'>
                <img className='research-img' src={this.props.img}></img>
        <div className='research-text'>{this.props.text}</div>
            </div>
        );
    }
}

export default ReasearchBlock;