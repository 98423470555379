import React, { Component } from 'react';
import './home.css'
import ContentBlock from './Home/Block/ContentBlock';
import ReasearchBlock from './Home/Block/ReasearchBlock';
import Firebase from '../utils/firebase'
import whitepaper from '../assets/whitepaper.png'
import blog from '../assets/blog.png'
import link from '../assets/link.png'
import presentation from '../assets/presentation.png'
import Slideshow from './Home/Slideshow'
import { Container } from 'semantic-ui-react';
import fourOfour from '../assets/404.png'

class Home extends Component {

    state = {
        data: []
    };

    componentDidMount() {
        console.log(this.props.location.pathname)
        this.getData(this.props.location.pathname); // function call
    }

    getData = async (path) => {
        let ref = Firebase.database().ref(path);
        ref.on('value', snapshot => {
            const state = snapshot.val();
            this.setState({data: state});
        });
    };

    render() {
        const {data} = this.state
        console.log(data)
        if (!data) {
            this.props.history.push("/")
            return <div className="not-found">
                <br/>
                <Container>
                    <img src={fourOfour}/>
                </Container>
                 </div>
        }
        
        return (
            <div>
                <Container>
                <br />
                <br />
                    {  data && data.slideImages && data.slideImages.length && <Slideshow slideImages={data.slideImages} className="slideshow-container"/>}
                    <div className='material-container'>
                        <div className="material">
                            <br />
                            <div className="header-text" ><h2><b>{data.title}</b></h2> </div>
                            <br />
                            <p style={{fontSize: "medium"}}>{data.description}</p>
                            <br />
                            <br />
                            <br />
                            {
                              data && data.home && data.home.length && data.home.map((d, index) =>
                                    <ContentBlock index={index} data={d} key={index} />
                                )
                            }
                            <br />
                        </div>
                    </div>

                </Container>
            </div>
        );
    }
}

export default Home;