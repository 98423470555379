import React, { Component } from 'react';
import '../home.css'
import ContentBlock from './Block/ContentBlock';
import ReasearchBlock from './Block/ReasearchBlock';
import Firebase from '../../utils/firebase'
import whitepaper from '../../assets/whitepaper.png'
import blog from '../../assets/blog.png'
import link from '../../assets/link.png'
import presentation from '../../assets/presentation.png'
import Slideshow from './Slideshow'
import { Container } from 'semantic-ui-react';

class Home extends Component {

    state = {
        data: []
    };

    componentDidMount() {
        this.getData(); // function call
    }

    getData = async () => {
        let ref = Firebase.database().ref('/');
        ref.on('value', snapshot => {
            const state = snapshot.val();
            this.setState({data: state});
        });
    };

    render() {
        const {data} = this.state
        console.log(data)
        return (
            <div>
                <Container>
                <br />
                <br />
                    {  data && data.slideImages && data.slideImages.length && <Slideshow slideImages={data.slideImages} className="slideshow-container"/>}
                    
                    <div className='material-container'>
                        <div className="material">
                            <br />
                            <div className="header-text" ><h2><b>COLLABORATE AND ENGAGE</b></h2> </div>
                            <br />
                            <br />
                            <br />
                            {
                              data && data.home && data.home.length && data.home.map((d, index) =>
                                    <ContentBlock index={index} data={d} key={index} />
                                )
                            }
                            <br />
                            <div className="header-text" ><h2><b>RESEARCH AND LEARN</b></h2> </div>
                            <br />
                            <br />
                            <br />

                            <div className="reasearch" >
                                {/* {
                                    data.map((d, index) =>
                                        <ReasearchBlock index={index} data={d} key={index} />
                                    )
                                } */}
                                <ReasearchBlock text='WHITE PAPERS' img={whitepaper} />
                                <ReasearchBlock text='PRESENTATION' img={presentation} />
                                <ReasearchBlock text='BLOG' img={blog} />
                                <ReasearchBlock text='IMPORTANT LINKS' img={link} />
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>

                </Container>
            </div>
        );
    }
}

export default Home;