import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import './App.css';
import 'react-slideshow-image/dist/styles.css'
import 'semantic-ui-css/semantic.min.css'
import Home from './Components/Home/Home';
import Page from './Components/Page'
import Header from './Components/Navigation/Header';
import Footer from './Components/Navigation/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter >
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/:page" exact component={Page} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
