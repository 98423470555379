import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './content.css'

class ContentBlock extends Component {
    render() {
        const { index, data } = this.props
        return (
            <div className='block'>
                <img href={data.header} src={data.img} className='content-image' style={{ order: index % 2 }} />
                <div className='content-text' >

                    <div>
                    <Link className="black" to={data.header}><h3 style={{textAlign: index % 2 ? 'right' : 'left' }}>{data.header}</h3></Link>
                        <p>{data.text}</p>
                    </div>
                </div>

            </div>
        );
    }
}

export default ContentBlock;