import React, { Component } from 'react';
import './header.css'
import logo from "./../../assets/logo.jpg"

class Header extends Component {
    render() {
        return (
            <div className='header'>
                <div className='logo-container' >
                    <a href='/'><img className="logo" src={logo} /></a>
                    <div className='contact'>
                        <p> Address - 22213 Windy Pine Ct., Ashburn VA 20148 </p>
                        <p> Email - marketing@ojasys.com</p>
                        <p> Phone - +1 (571)-318-9007 </p>
                    </div>
                </div>
                <div className='menu-container'>
                    <button className="dropdown" href="/company">
                        Company
                        <div className="dropdown-content">
                            <a href="#">Our Philosophy</a>
                            <a href="#">About Us</a>
                            <a href="#">Contact Us</a>
                        </div>
                    </button>
                    <button className="dropdown" href="/colab">
                        Collaborate And Engage
                        <div className="dropdown-content">
                            <a href="#">IT Architecture</a>
                            <a href="#">Application Design and Development</a>
                            <a href="#">Business Intelligence and Reporting Solutions</a>
                            <a href="#">Machine Learning</a>
                            <a href="#">Full Turnkey Projects</a>
                            <a href="#">Staff Augmentation</a>
                        </div>
                    </button>
                    <button className="dropdown" href="/research">
                        Research and Learn
                        <div className="dropdown-content">
                            <a href="#">White Papers</a>
                            <a href="#">Presentation</a>
                            <a href="#">Blog</a>
                            <a href="#">Important Links</a>
                        </div>
                    </button>

                </div>
            </div>
        );
    }
}

export default Header;