import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyDVPymMRcpMqZiwazJiLACROnKA7CZQnfY",
    authDomain: "ojasys-001.firebaseapp.com",
    databaseURL: "https://ojasys-001.firebaseio.com",
    projectId: "ojasys-001",
    storageBucket: "ojasys-001.appspot.com",
    messagingSenderId: "1011627813335",
    appId: "1:1011627813335:web:e50da10a778617a4cac392"
  };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;