import React, { Component } from 'react';
import "./footer.css"


class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <div className='left'>
                    <div className='address'>@ OJASYS</div>
                </div>
                <div className='right'>
                    <div className='address'>22213 Windy Pine Ct., Ashburn VA 20148</div>
                </div>
            </footer>
        );
    }
}

export default Footer;