import React from 'react';
import { Slide } from 'react-slideshow-image';
import { Container } from 'semantic-ui-react';
import './slideshow.css'

const slideImages = [
    'https://cdn.pixabay.com/photo/2015/02/24/15/41/dog-647528__340.jpg',
    'https://cdn.pixabay.com/photo/2015/02/24/15/41/dog-647528__340.jpg',
    'https://cdn.pixabay.com/photo/2015/02/24/15/41/dog-647528__340.jpg'
];

const Slideshow = (props) => {
    const {slideImages} = props
    return (
        <div className="slideshow">
            <Slide easing="ease">
                {
                    slideImages && slideImages.length && slideImages.map((d) =>
                        <div className="each-slide">
                            <div style={{ 'backgroundImage': `url(${d})` }}>
                                {/* <span>Slide 1</span> */}
                            </div>
                        </div>
                    )
                }
            </Slide>
        </div>
    )
};

export default Slideshow;